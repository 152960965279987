<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-dialog v-model="modal" max-width="500" persistent>
      <v-card>
        <v-card-title v-if="!isMaster" class="headline">
          <span>
            {{
              $t(
                `subjects.msg_response.${
                  isMaster ? "master_subjects" : "subjects"
                }.confirm_force_delete`
              )
            }}
          </span>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <div v-if="isMaster">
            Master {{ $t("subjects.subjects") }}
            <b> {{ subject }} </b>
            <span class="red--text">{{ $t("subjects.cannot_delete") }}</span>
            {{ $t("subjects.has_been_relation") }}
            <b> {{ totalRelations }} Data </b>
          </div>
          <div v-else>
            {{ $t("subjects.subjects") }} {{ subject }}
            {{ $t("subjects.has_been_used") }} <br />
            {{ $t("app.class") }} :
            {{ className }}
            <br />
            <span v-if="subjectsConflict.elearnings">
              Elearning : {{ subjectsConflict.elearnings }} <br />
            </span>
            <span v-if="subjectsConflict.employeeJournals">
              Jurnal : {{ subjectsConflict.employeeJournals }} <br />
            </span>
            <span v-if="subjectsConflict.lessonSchedules">
              {{ $t("subjects.schedules") }} :
              {{ subjectsConflict.lessonSchedules }} <br /><br />
            </span>
            <span v-if="!isMaster" class="font-weight-bold">
              {{ $t("subjects.confirm_password") }}
            </span>
          </div>
          <v-text-field
            v-model="passwordConfirm"
            v-if="!isMaster"
            :type="showPwd ? 'text' : 'password'"
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="
              formValidation(
                {
                  a: $t('login.password_rules'),
                  b: $t('login.password_rules_minlength')
                },
                'password'
              )
            "
            prepend-inner-icon="mdi-lock"
            dense
            class="mt-3"
            outlined
            label="Password"
            @keyup.enter="forceDelete"
            @click:append="showPwd = !showPwd"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="isMaster"
            color="gradient-primary"
            dark
            depressed
            @click="close"
          >
            OK
          </v-btn>
          <div v-else>
            <v-btn
              :disabled="loadingForceDelete"
              outlined
              color="primary"
              class="mr-2"
              @click="close"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-btn
              :loading="loadingForceDelete"
              :disabled="!valid"
              color="gradient-primary"
              dark
              depressed
              @click="forceDelete"
            >
              {{ $t("app.save") }}
            </v-btn>
          </div>
          <!-- <v-btn
              :disabled="loadingForceDelete"
              outlined
              color="primary"
              @click="close"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-btn
              :loading="loadingForceDelete"
              :disabled="!valid"
              color="gradient-primary"
              dark
              depressed
              @click="forceDelete"
            >
              {{ $t("app.save") }}
            </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { deleteSubjects, deleteMaster } from "@/api/admin/academic/subjects";
export default {
  props: {
    subject: String,
    className: String,
    subjectsConflict: Object,
    modal: Boolean,
    bodyDelete: Object,
    isMaster: Boolean,
    totalRelations: Number // required if isMaster true
  },
  data() {
    return {
      showPwd: false,
      loadingForceDelete: false,
      valid: false,
      elearnings: 0,
      employeeJournals: 0,
      lessonSchedules: 0,
      passwordConfirm: "",
      nameClass: ""
    };
  },
  methods: {
    close() {
      this.passwordConfirm = "";
      this.$refs.form.resetValidation();
      this.$emit("close");
    },
    async forceDelete() {
      if (this.$refs.form.validate()) {
        this.loadingForceDelete = true;
        const body = {
          ...this.bodyDelete,
          force: true,
          password: this.passwordConfirm
        };
        try {
          const res = this.isMaster
            ? await deleteMaster(body)
            : await deleteSubjects(body);
          if (res.data.code) {
            this.snackBar(
              true,
              this.$i18n.t(
                `subjects.msg_response.${
                  this.isMaster ? "master_subjects" : "subjects"
                }.success_deleted`
              )
            );
            this.close();
          } else {
            this.snackBar(false, res.data.message);
          }
          this.loadingForceDelete = false;
        } catch (error) {
          console.error("forceDelete()\n", error);
          this.loadingForceDelete = false;
        }
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    formValidation(text, type) {
      switch (type) {
        case "password":
          return [v => !!v || text.a, v => v.length >= 6 || text.b];
        default:
          return [v => !!v || text];
      }
    }
  }
};
</script>
